import styled, { keyframes } from "styled-components";

const slideInDownAnimation = (height) => keyframes`
from {
    transform: translateY(-${height});
  }

  to {
    transform: translateY(0%);
  }
`;

export const StyledSubMenuHint = styled.div`
  position: ${({ sticky }) => (sticky ? "fixed" : "absolute")};
  top: ${({ respectSubMenuHeight, theme }) =>
    respectSubMenuHeight
      ? `calc(${theme.headerHeight} + ${theme.subMenuHeight})`
      : theme.headerHeight};
  width: 100%;
  height: ${({ theme }) => theme.subMenuHintHeight};
  background-color: ${({ noBackgroundColor, theme, inverted }) =>
    noBackgroundColor
      ? "rgba(255,255,255,0.9)"
      : inverted
      ? theme.color.backgroundSubMenuHintInverted
      : theme.color.backgroundSubMenuHint};
  display: flex;
  align-items: center;
  z-index: 997;
  animation: 1s ${({ theme }) => slideInDownAnimation(theme.subMenuHintHeight)};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 9px 2px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;
