import styled from "styled-components";
import Container from "../Container/Container";

export const StyledBlock = styled.div`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.color.backgroundLight};
  height: ${({ theme, height, minified }) =>
    height ||
    `calc(100vh - ${
      minified ? theme.productHeaderHeight : theme.headerHeight
    })`};
  margin: ${({ margin }) => margin || "0 0 0px 0"};
  overflow: ${({ overflow }) => overflow || "hidden"};
  position: ${({ sticky }) => (sticky ? "sticky" : "relative")};
  top: ${({ theme, sticky, minified }) =>
    sticky
      ? minified
        ? theme.productHeaderHeight
        : theme.headerHeight
      : "0px"};
  ${({ backgroundImage }) =>
    backgroundImage &&
    `
    background-image: url(${backgroundImage});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  `}
`;

export const CustomContainer = styled(Container)`
  height: 100%;
`;
