import { v4 as uuidv4 } from 'uuid'

export const projectName = 'MatchMyEstate - Homepage'

export const backendDomain =
  process.env.NODE_ENV === 'production' ? 'https://api.matchmyestate.de' : 'http://localhost:8100'

export const getUUID = () => uuidv4()

export const getQueryString = (string, requiredString) => {
  if (typeof string === 'string' && string.length > 0 && typeof requiredString === 'string') {
    string = string.substring(1)
    const split = string.split('=')
    return !string.includes('&') &&
      split.length === 2 &&
      split[0] === requiredString &&
      split[1].length > 0
      ? decodeURIComponent(split[1])
      : false
  } else {
    return false
  }
}

export const frontendDomain =
  process.env.NODE_ENV === 'production' ? 'https://matchmyestate.de' : 'http://localhost:3000'

export const getPrettyTime = (date) => {
  // date.setMinutes(date.getMinutes() + (date.getTimezoneOffset() * -1))
  const fill = (number) => {
    number = `${number}`
    return number.length === 1 ? `0${number}` : number
  }
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${hour}:${minute}`
}
export const getPrettyDateTime = (date) => {
  // date.setMinutes(date.getMinutes() + (date.getTimezoneOffset() * -1))
  const fill = (number) => {
    number = `${number}`
    return number.length === 1 ? `0${number}` : number
  }
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${day}.${month}.${year} ${hour}:${minute}`
}
export const getPrettyDate = (date) => {
  const fill = (number) => {
    number = `${number}`
    return number.length === 1 ? `0${number}` : number
  }
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

export const addMissingZero = (input) => {
  if (`${input}`.length === 1) {
    return `0${input}`
  }
  return input
}

export const maxMobileWidth = 992

export const presignedUploadURLEndpoint = '/upload/get-presigned-url'
