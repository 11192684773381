import bg from "../../assets/image/product/dcenter/bg.png";
import Flex from "../UI/Flex/Flex";
import Block from "../UI/Block/Block";
import dcenter from "../../assets/image/product/dcenter/dcenter-detail.png";
import logo from "../../assets/image/product/dcenter/logo.svg";
import backgroundText from "../../assets/image/product/dcenter/backgroundText.svg";
import {
  Title,
  ImageWrapper,
  Image,
  Layer,
  StyledDcenter,
  SmallTitle,
  SlideInContent,
  SubTitle,
  CTA,
  BackgroundText,
} from "./Dcenter.Styled";
import { useSelector } from "react-redux";
import Layout from "../UI/Layout/Layout";
import Detail from "./Detail/Detail";

const Intro = () => {
  const translation = useSelector((s) => s.translation);

  return (
    <Layout logo={logo} headerLineColor={"petrol"} showFooter={true}>
      <StyledDcenter>
        <Block backgroundColor={"transparent"} backgroundImage={bg}>
          <Layer zIndex={1}>
            <BackgroundText src={backgroundText} />
          </Layer>
          <Layer zIndex={2} centerContent>
            <Flex wrap="no-wrap" align="center" gap="10vw">
              <SlideInContent animation={"fadeInLeft"}>
                <SmallTitle>{translation.dcenter.smallTitle}</SmallTitle>
                <Title>
                  {translation.dcenter.title.text1}
                  <br />
                  {translation.dcenter.title.text2}
                </Title>
                <SubTitle>{translation.dcenter.subtitle}</SubTitle>
                <CTA>{translation.dcenter.cta}</CTA>
              </SlideInContent>
              <ImageWrapper animation={"fadeInRight"}>
                <Image src={dcenter} />
              </ImageWrapper>
            </Flex>
          </Layer>
        </Block>
        <Detail />
      </StyledDcenter>
    </Layout>
  );
};

export default Intro;
