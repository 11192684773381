import Container from '../UI/Container/Container'
import { StyledSubMenu, Animated, FullWidth } from './SubMenu.Styled'
const SubMenu = ({ children, background, noContainer }) => {
  return (
    <StyledSubMenu background={background}>
      {noContainer && (
        <FullWidth>
          <Animated animation="slideInRight">{children}</Animated>
        </FullWidth>
      )}
      {!noContainer && (
        <Container>
          <Animated animation="slideInRight">{children}</Animated>
        </Container>
      )}
    </StyledSubMenu>
  )
}

export default SubMenu
