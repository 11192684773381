import Block from "../../UI/Block/Block";
import {
  Image,
  CustomFlex,
  Content,
  TextWrapper,
  FixLink,
  Headline,
  Text,
} from "./Selector.Styled";
import { useRef } from "react";
import bgImage from "../../../assets/image/product/selector/bg.png";
import fixLink from "../../../assets/image/product/selector/fixlink.png";
import { useSelector } from "react-redux";
import useIsVisible from "../../helper/IsVisible/useIsVisible";

const Selector = () => {
  const blockRef = useRef(null);
  const isVisible = useIsVisible(blockRef);
  const translation = useSelector((s) => s.translation);

  return (
    <Block ref={blockRef} backgroundImage={bgImage} height={"60vh"}>
      <CustomFlex>
        <Content isVisible={isVisible}>
          <Image src={fixLink} />
          <TextWrapper>
            <FixLink>{translation.product.selector.fixlink}</FixLink>
            <Headline>{translation.product.selector.headline}</Headline>
            <Text>{translation.product.selector.text}</Text>
          </TextWrapper>
        </Content>
      </CustomFlex>
    </Block>
  );
};

export default Selector;
