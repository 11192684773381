import {
  StyledDetail,
  Headline,
  Text,
  DetailSection,
  ImageWrapper,
  StyledSingleDetail,
  SingleDetailValue,
  SingleDetailText,
  DetailWrapper,
  Image,
  HeadlineHint,
} from "./Detail.Styled";
import Block from "../../UI/Block/Block";
import { useSelector } from "react-redux";
import Container from "../../UI/Container/Container";
import dcenter from "../../../assets/image/product/dcenter/dcenter-detail.png";
import bg from "../../../assets/image/product/dcenter/bg-detail.png";
import useIsVisible from "../../helper/IsVisible/useIsVisible";
import { useRef } from "react";

const AnimateIfVisible = ({ children, ...props }) => {
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);

  return (
    <DetailSection ref={ref} style={{ width: props.width }}>
      {isVisible && children}
    </DetailSection>
  );
};

const SingleDetail = ({ data, right }) => {
  return (
    <StyledSingleDetail
      right={right}
      duration="1s"
      animation={right ? "fadeInLeft" : "fadeInRight"}
    >
      <SingleDetailValue>{data.value}</SingleDetailValue>
      <SingleDetailText>{data.text}</SingleDetailText>
    </StyledSingleDetail>
  );
};

const Detail = () => {
  const translation = useSelector((s) => s.translation);
  return (
    <Block backgroundColor={"transparent"} backgroundImage={bg} height={"100%"}>
      <StyledDetail>
        <Container>
          <HeadlineHint>{translation.dcenter.detail.headlineHint}</HeadlineHint>
          {translation.dcenter.detail.headline.map((h) => (
            <Headline key={h}>{h}</Headline>
          ))}
          <AnimateIfVisible>
            <DetailWrapper justify={"right"}>
              <SingleDetail right data={translation.dcenter.detail.sices} />
              <SingleDetail
                right
                data={translation.dcenter.detail.components}
              />
            </DetailWrapper>
            <ImageWrapper animation="fadeInUp" duration="1s">
              <Image src={dcenter} />
            </ImageWrapper>
            <DetailWrapper>
              <SingleDetail data={translation.dcenter.detail.scenario} />
              <SingleDetail data={translation.dcenter.detail.tubes} />
            </DetailWrapper>
          </AnimateIfVisible>
          <Text>{translation.dcenter.detail.text}</Text>
        </Container>
      </StyledDetail>
    </Block>
  );
};

export default Detail;
