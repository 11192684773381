import { StyledLink } from './Link.Styled'
import { useNavigate } from 'react-router-dom'

const Link = ({ href, text, size, color, margin, className, onClick, newTab = false }) => {
  const navigate = useNavigate()

  const trigger = (e) => {
    e.preventDefault()
    if (href) {
      if (newTab) {
        window.open(href, '_blank').focus()
      } else {
        navigate(href)
        window.scrollTo(0, 0)
      }
    }
  }

  return (
    <StyledLink
      href={href}
      onClick={onClick || trigger}
      size={size}
      color={color}
      margin={margin}
      className={className}
    >
      {text}
    </StyledLink>
  )
}

export default Link
