const _ = require("lodash");

const red = "rgb(248,19,65)";
const petrol = "rgb(0,170,169)";

const theme = {
  size: {
    sm: "16px",
    md: "22px",
    lg: "30px",
    xlg: "38px",
  },
  color: {
    font: {
      primary: "rgb(31,30,30)",
      secondary: "white",
      thirdary: "#777777",
      highlightOnLight: "#4CACC8",
      highlightOnDark: "#81E5EE",
      subMenuLink: "rgb(63,63,63)",
      danger: red,
      petrol,
    },
    button: {
      background: "rgb(30, 29, 29)",
      font: "#ffffff",
    },
    headerLine: {
      blue: "rgb(0,149,248)",
      red,
      petrol,
    },
    input: {
      background: "#ffffff",
      font: "#000000",
      border: "#ffffff",
    },
    background: "rgb(30, 29, 29)",
    backgroundLight: "#ffffff",
    backgroundDark: "#003264",
    backgroundTorquoise: "#4CACC8",
    backgroundHeader: "rgb(31,30,30)",
    backgroundFooter: "rgb(31,30,30)",
    backgroundSubMenu: "rgb(142,142,142)",
    backgroundSubMenuHint: "rgb(240,240,240)",
    backgroundSubMenuHintInverted: "rgba(255,255,255, 0.3)",
    backgroundRed: red,
    backgroundPetrol: petrol,
    border: {
      dark: "#000000",
      light: "#ffffff",
    },
    primary: "#118bdc",
    secondary: "rgb(236,237,242)",
    danger: "#E74C3C",
  },
  breakpoint: {
    sm: "768px",
    md: "992px",
    lg: "1300px",
  },
  border: {
    radius: "7px",
  },
  shadow: "2px 2px 16px -1px rgba(0,0,0,0.48)",
  productHeaderHeight: "65px",
  headerHeight: "100px",
  subMenuHeight: "50px",
  subMenuHintHeight: "100px",
};

const getTheme = (originTheme = {}) => _.merge({}, theme, originTheme);

module.exports = getTheme;
