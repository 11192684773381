import _ from 'lodash'

export const set = (state, action) => {
  return { ...state, cart: action.payload }
}

export const add = (state, action) => {
  const item = action.payload
  const cartCopy = _.cloneDeep(state.cart)
  const existingItem = cartCopy.find((el) => el.uuid === item.uuid)

  if (existingItem) {
    existingItem.productCount++
  } else {
    cartCopy.push({ ...item, productCount: 1 })
  }

  return { ...state, cart: cartCopy }
}

export const remove = (state, action) => {
  const item = action.payload
  const cartCopy = _.cloneDeep(state.cart)
  const existingItem = cartCopy.find((el) => el.uuid === item.uuid)

  if (existingItem && existingItem.productCount > 1) {
    existingItem.productCount--
  } else {
    const newCart = cartCopy.filter((el) => el.uuid !== item.uuid)
    return { ...state, cart: newCart }
  }

  return { ...state, cart: cartCopy }
}

export const removePosition = (state, action) => {
  return { ...state, cart: state.cart.filter((el) => el.uuid !== action.payload.uuid) }
}
