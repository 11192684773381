import styled, { keyframes } from "styled-components";
import { Animated } from "../../helper/Animated/Animated.Styled";
import Flex from "../../UI/Flex/Flex";

export const StyledProduct = styled.div`
  min-height: 100vh;
`;

export const Title = styled(Animated)`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding-top: 300px;
`;

export const Split = styled.div`
  flex: 1 1 0px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CustomFlex = styled(Flex)`
  flex-wrap: nowrap;
  margin: 100px 0;
  height: 100%;
`;

const slideInUpAnimation = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
  `;

export const Image = styled.img`
  animation: 3s ${slideInUpAnimation};
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

export const SubMenuHintItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ highlight, theme }) =>
    highlight ? theme.size.md : theme.size.sm};
`;

export const BuyButton = styled.img`
  height: 30px;
`;

export const BackgroundImage = styled(Animated)`
  background-image: ${({ img }) => `url(${img})`};
  height: ${({ theme, height }) =>
    height || `calc(100vh - ${theme.headerHeight})`};
  width: 100%;
  background-size: cover;
  background-position: center center;
`;

export const FixLink = styled.div`
  color: ${({ theme }) => theme.color.font.danger};
  text-align: ${({ align }) => align || "left"};
`;

export const Text = styled.div`
  text-align: ${({ align }) => align || "left"};
  font-family: "thin";
`;
