import { StyledProduct } from "./Product.Styled";
import Layout from "../UI/Layout/Layout";
import TextHighlight from "./TextHighlight/TextHighlight";
import Exchange from "./Exchange/Exchange";
import Intro from "./Intro/Intro";
import SideSlide from "./SideSlide/SideSlide";
import { useSelector } from "react-redux";
import ProductHeader from "../UI/Layout/ProductHeader/ProductHeader";
import easylan from "../../assets/image/easyLan/easylan.svg";

const Product = () => {
  const translation = useSelector((s) => s.translation);
  return (
    <Layout logo={easylan} headerLineColor={"red"} showFooter={true}>
      <StyledProduct>
        <ProductHeader why={translation.easylan.smallTitle} color={"red"} />
        <Intro />
        <Exchange />
        <TextHighlight />
        <SideSlide />
      </StyledProduct>
    </Layout>
  );
};

export default Product;
