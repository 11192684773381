import { useState } from "react";
import {
  StyledFlashIntroScreen,
  Wrapper,
  Image,
} from "./FlashIntroScreen.Styled";
import image from "../../../../assets/image/flashIntroScreen/grid.png";
import { useEffect } from "react";

const FlashIntroScreen = ({ enable, children }) => {
  const duration = 1;
  const [done, setDone] = useState(!enable);

  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    }, duration * 1000);
  });

  return (
    <StyledFlashIntroScreen>
      {!done && (
        <Wrapper duration={duration}>
          <Image duration={duration} src={image} />
        </Wrapper>
      )}
      {children}
    </StyledFlashIntroScreen>
  );
};

export default FlashIntroScreen;
