import Layout from "../UI/Layout/Layout";
import { StyledHome, Wrapper, Image, Layer, Background } from "./Home.Styled";
import image from "../../assets/image/home/image.png";
import Sphere from "../UI/Sphere/Sphere";

const Home = () => {
  return (
    <Layout
      flashIntroScreen
      addHeaderPadding={false}
      glassHeader={true}
      headerLineColor={"blue"}
    >
      <StyledHome>
        <Wrapper>
          <Layer zIndex={0}>
            <Sphere size={750} />
          </Layer>
          <Layer zIndex={1}>
            <Background />
          </Layer>
          <Layer zIndex={2}>
            <Image src={image} />
          </Layer>
        </Wrapper>
      </StyledHome>
    </Layout>
  );
};

export default Home;
