import styled, { keyframes } from "styled-components";
import background from "../../../../assets/image/flashIntroScreen/bg.png";

export const StyledFlashIntroScreen = styled.div``;

export const fadeout = keyframes`
    0% {
		opacity: 1;
	}
	/* 50% {
        transform: scale(1.25);
	} */
	100% {
        opacity: 0;
	}
`;

export const Wrapper = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: url(${background});
  background-color: ${({ theme }) => theme.color.background};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: ${fadeout} ${({ duration }) => duration}s ease-in-out;
  animation-delay: 0.2s;
  overflow: hidden;
`;

export const pulse = keyframes`
    0% {
		transform: scale(1);
	}
	/* 50% {
        transform: scale(1.25);
	} */
	100% {
        transform: scale(100);
	}
`;

export const Image = styled.img`
  height: 100vh;
  animation: ${pulse} ${({ duration }) => duration}s ease-in-out;
  animation-delay: 0.4s;
`;
