import styled from "styled-components";
import { Animated as AnimatedStyle } from "../helper/Animated/Animated.Styled";

export const StyledSubMenu = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.headerHeight};
  width: 100%;
  height: ${({ theme }) => theme.subMenuHeight};
  display: flex;
  align-items: center;
  z-index: 998;
  background-color: ${({ theme, background }) =>
    background ? background : theme.color.backgroundSubMenu};
`;

export const Animated = styled(AnimatedStyle)``;
export const FullWidth = styled.div`
  width: 100%;
  padding: 0px 15px;
`;
