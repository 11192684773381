import styled from "styled-components";

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundFooter};
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 125px;
`;

export const Separator = styled.div`
  display: inline-block;
  color: ${({ theme, color }) => theme.color.headerLine[color || "red"]};
`;

export const Text = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.sm};
  text-transform: uppercase;
`;
