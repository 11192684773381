import {
  StyledHeader,
  Item,
  ItemWrapper,
  Float,
  CustomLink,
  Icon,
  LogoLink,
  NavItems,
} from "./Header.Styled";
import logoImage from "../../../../assets/image/logo.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Container from "../../Container/Container";

const Header = ({ glassHeader, logo, lineColor = "blue" }) => {
  const translation = useSelector((s) => s.translation);
  const [userScrolled, setUserScrolled] = useState(false);

  const handleScroll = () => {
    const result = window.scrollY > 100;
    if (result !== userScrolled) {
      setUserScrolled(result);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <StyledHeader lineColor={lineColor}>
      <Float userScrolled={userScrolled} glassHeader={glassHeader}>
        <Container>
          <ItemWrapper>
            <Item>
              <LogoLink href={"/"}>
                <Icon src={logo || logoImage} height="40px" />
              </LogoLink>
            </Item>

            <NavItems>
              <Item>
                <CustomLink
                  href={"/product-category"}
                  text={
                    logo
                      ? translation.header.products
                      : translation.header.brands
                  }
                />
              </Item>
              <Item>
                <CustomLink
                  href={"/"}
                  text={translation.header.sustainability}
                />
              </Item>
              <Item>
                <CustomLink href={"/"} text={translation.header.location} />
              </Item>
              <Item>
                <CustomLink href={"/"} text={translation.header.career} />
              </Item>
              <Item>
                <CustomLink href={"/"} text={translation.header.downloads} />
              </Item>
            </NavItems>
          </ItemWrapper>
        </Container>
      </Float>
    </StyledHeader>
  );
};

export default Header;
