import styled, { keyframes } from "styled-components";
import { Animated } from "../helper/Animated/Animated.Styled";

export const SmallTitle = styled.div`
  color: white;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.font.petrol};
`;

export const Title = styled.div`
  color: white;
  font-size: 70px;
  font-family: "bold";
  margin: 50px 0;
`;

export const SubTitle = styled.div`
  color: white;
  font-weight: bold;
  margin-top: 15px;
  font-size: ${({ theme }) => theme.size.sm};
`;

export const CTA = styled.div`
  padding: 5px 30px;
  display: inline-block;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.color.backgroundPetrol};
  color: ${({ theme }) => theme.color.font.secondary};
  -webkit-box-shadow: 0px 0px 10px -5px #000000;
  box-shadow: 0px 0px 10px -5px #000000;
  margin-top: 10px;
`;

export const SlideInContent = styled(Animated)``;

export const ImageWrapper = styled(Animated)`
  text-align: center;
  position: relative;
`;

export const Image = styled.img`
  height: 500px;
`;

export const Layer = styled.div`
  position: absolute;
  min-height: 100vh;
  min-width: 100%;
  z-index: ${({ zIndex }) => zIndex || 1};
  ${({ centerContent }) =>
    centerContent &&
    `
    display: flex;
  align-items: center;
  justify-content: center;
  `}
`;

export const StyledDcenter = styled.div``;

export const resize = keyframes`
    0% {
    width: 275px;
	}
	50% {
    width: 250px;
	}
	100% {
    width: 275px;
	}
`;

export const Shadow = styled.div`
  height: 30px;
  bottom: -40px;
  background: rgb(0, 120, 240);
  background: linear-gradient(
    180deg,
    rgba(0, 120, 240, 0.7) 0%,
    rgba(49, 93, 170, 0.7) 100%
  );
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  animation: ${resize} 3s ease-in-out infinite;
`;

export const BackgroundText = styled.div`
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  width: 100%;
  background-image: url(${({ src }) => src});
  background-position: center 20vh;
  background-size: 90%;
  background-repeat: no-repeat;
`;
