import styled from "styled-components";
import Block from "../UI/Block/Block";

export const StyledProductCategory = styled.div`
  /* display: none; */
`;
export const Split = styled.div`
  flex: 1 1 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const World = styled.img`
  width: 80%;
`;

export const CustomFlex = styled.div`
  display: flex;
  height: 100%;
`;

export const Text = styled.div``;
export const Headline = styled.div`
  font-size: 50px;
  font-family: "bold";
  text-align: center;
`;
export const Hint = styled.div`
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.color.font.thirdary};
`;

export const Link = styled.a`
  display: block;
  text-align: center;
`;
export const ButtonImage = styled.img`
  height: 60px;
  margin-top: 15px;
`;
export const Spacer = styled.div`
  height: ${({ height }) => height || "50px"};
`;

export const CaseImage = styled.img`
  width: 35vw;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

export const SubMenuHintText = styled.span`
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.subMenuLink};
`;
export const SubMenuHintLink = styled.a`
  font-size: ${({ theme }) => theme.size.sm};
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const IconLink = styled.a``;

export const StyledIcon = styled.img`
  height: 50px;
`;

export const Center = styled.div`
  text-align: center;
`;

export const CustomBlock = styled(Block)`
  min-height: calc(100vh - ${({ theme }) => theme.headerHeight});
  height: fit-content;
`;
