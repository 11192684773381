import {
  StyledProductHeader,
  Item,
  ItemWrapper,
  Float,
  CustomLink,
  NavItems,
  BuyCTA,
  SplitCustomLink,
  SplitCustomLinkText,
} from "./ProductHeader.Styled";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Container from "../../Container/Container";

const ProductHeader = ({ why, glassHeader, color = "red" }) => {
  const translation = useSelector((s) => s.translation);
  const [userScrolled, setUserScrolled] = useState(false);

  const handleScroll = () => {
    const result = window.scrollY > 100;
    if (result !== userScrolled) {
      setUserScrolled(result);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <StyledProductHeader color={color}>
      <Float userScrolled={userScrolled} glassHeader={glassHeader}>
        <Container>
          <ItemWrapper>
            <NavItems>
              {why && (
                <Item>
                  <SplitCustomLink>
                    <SplitCustomLinkText>
                      {translation.header.whyPrefix}
                    </SplitCustomLinkText>
                    <SplitCustomLinkText color={"secondary"}>
                      {why}
                    </SplitCustomLinkText>
                  </SplitCustomLink>
                </Item>
              )}
              <Item>
                <CustomLink text={translation.header.technicalData} />
              </Item>
              <Item>
                <BuyCTA>{translation.header.buyCTA}</BuyCTA>
              </Item>
            </NavItems>
          </ItemWrapper>
        </Container>
      </Float>
    </StyledProductHeader>
  );
};

export default ProductHeader;
