import styled from "styled-components";
import Image from "../../Image/Image";
import Link from "../../Link/Link";

export const StyledProductHeader = styled.div`
  width: 100vw;
  height: ${({ theme }) => theme.productHeaderHeight};
  z-index: 998;
  position: absolute;
  width: 100%;
  background-color: ${({ theme, color }) => theme.color.headerLine[color]};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

export const LogoLink = styled.a``;

export const Float = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 30px;
  transition: all 0.5s ease-in-out;
`;

export const LogoWrapper = styled.div`
  width: 25%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const Item = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || "center"};

  &:hover {
    .popover {
      display: block !important;
    }
  }
  /* @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: auto;
    display: ${({ mobileVisible }) => (mobileVisible ? "block" : "none")};
    text-align: right;
  } */
`;

export const StyledToggle = styled.img`
  height: 30px;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 30px;
  }
`;

export const Navigation = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundDark};
  z-index: 998;
  position: absolute;
  width: 100vw;
  height: calc(100vh - 100px);
  top: 100px;
  left: 0px;
  padding: 30px;
`;

export const CustomLink = styled(Link)`
  font-size: 16px;
  ${({ highlight, theme }) =>
    highlight &&
    `
    color: ${theme.color.font.highlightOnDark};
    text-decoration: underline;
    `}
  ${({ button, theme }) =>
    button &&
    `
    border: 2px solid ${theme.color.border.light};
    border-radius: ${theme.border.radius};
    box-shadow: 0px 0px 10px -4px #ffffff;
    padding: 10px 10px 7px;
    @media (max-width: ${theme.breakpoint.md}) {
      text-align: center;
    }
    `}
  cursor: ${({ noClick }) => (noClick ? "default" : "pointer")};
`;

export const Logo = styled(Image)`
  height: 40px;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 30px;
  }
`;

export const Icon = styled.img`
  height: ${({ height }) => (height ? height : "20px")};
  display: flex;
`;

export const NavItems = styled.div`
  display: flex;
  justify-content: right;
  gap: 5vw;
`;

export const BuyCTA = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundHeader};
  text-transform: uppercase;
  padding: 7.5px 30px;
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.sm};
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 10px -5px #000000;
  box-shadow: 0px 0px 10px -5px #000000;
`;

export const SplitCustomLink = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
`;

export const SplitCustomLinkText = styled.div`
  ${({ theme, color }) => color && `color: ${theme.color.font[color]};`}
  font-size: 16px;
`;
