import styled from "styled-components";
import bg from "../../assets/image/home/bg.png";

export const StyledHome = styled.div`
  min-height: 100vh;
  /* height: ${({ theme }) => `calc(100vh - ${theme.headerHeight})`}; */
  overflow: hidden;
  /* background-image: url(${bg}); */
  /* background-color: ${({ theme }) => theme.color.background}; */
  background-color: black;
`;
export const Split = styled.div`
  flex: 1 1 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const World = styled.img`
  width: 80%;
`;

export const CustomFlex = styled.div`
  display: flex;
  height: 100%;
`;

export const Text = styled.div``;
export const Headline = styled.div`
  font-size: 50px;
  font-family: "bold";
  text-align: center;
`;
export const Hint = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  text-align: center;
`;

export const Link = styled.a`
  display: block;
  text-align: center;
`;
export const ButtonImage = styled.img`
  height: 40px;
  margin-top: 15px;
`;
export const Spacer = styled.div`
  height: 50px;
`;

export const Partner = styled.div`
  /* font-size: ${({ theme }) => theme.size.lg}; */
  font-size: 90px;
  color: ${({ theme }) => theme.color.secondary};
  text-transform: uppercase;
  width: 100%;
  font-family: "bold";
  text-align: center;
`;

export const Subline = styled.div`
  /* font-size: ${({ theme }) => theme.size.md}; */
  color: ${({ theme }) => theme.color.secondary};
  width: 100%;
  font-size: 38px;
  font-family: "medium";
  text-align: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  margin-top: calc(${({ theme }) => theme.headerHeight});
`;

export const Image = styled.img`
  height: 45vh;
`;

export const Layer = styled.div`
  z-index: ${({ zIndex }) => zIndex};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Background = styled.div`
  /* background-color: rgba(255, 255, 255, 0.2); */
  height: 100%;
  width: 100%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;
