import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setResponsiveState } from "../../../../reducer/action/responsiveState";

const useMediaQuery = (maxWidth) => {
  const [state, setState] = useState({
    windowWidth: window.innerWidth,
    isDesiredWidth: false,
  });

  useEffect(() => {
    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth;
      const isDesiredWidth = currentWindowWidth < maxWidth;
      setState({ windowWidth: currentWindowWidth, isDesiredWidth });
    };
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, [state.windowWidth, maxWidth]);

  return state.isDesiredWidth;
};

const ResponsiveState = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(992);

  useEffect(() => {
    dispatch(setResponsiveState({ isMobile }));
  }, [isMobile, dispatch]);

  return <></>;
};

export default ResponsiveState;
