import Flex from "../../UI/Flex/Flex";
import Block from "../../UI/Block/Block";
import {
  Title,
  ImageWrapper,
  Image,
  Layer,
  SmallTitle,
  SlideInContent,
  SubTitle,
  CTA,
  IconWrapper,
  Icon,
} from "./Intro.Styled";
import { useSelector } from "react-redux";
import wire from "../../../assets/image/product/sample.svg";
import bgText from "../../../assets/image/product/bg-text.png";
import icon from "../../../assets/image/product/fixlink.svg";
import Container from "../../UI/Container/Container";

const Intro = () => {
  const translation = useSelector((s) => s.translation);

  return (
    <Block minified backgroundColor={"rgb(240,240,240)"}>
      <Layer zIndex={0}>
        <IconWrapper>
          <Container>
            <Icon src={icon} />
          </Container>
        </IconWrapper>
      </Layer>
      <Layer img={bgText} zIndex={1} centerContent>
        <Flex wrap="no-wrap" align="center" gap={"100px"}>
          <ImageWrapper animation={"fadeInLeft"}>
            <Image src={wire} />
          </ImageWrapper>
          <SlideInContent animation={"fadeInRight"}>
            <SmallTitle>{translation.easylan.smallTitle}</SmallTitle>
            <Title>
              {translation.easylan.title.text1}
              <br />
              {translation.easylan.title.text2}
            </Title>
            <SubTitle>{translation.easylan.subtitle}</SubTitle>
            <CTA>{translation.easylan.cta}</CTA>
          </SlideInContent>
        </Flex>
      </Layer>
    </Block>
  );
};

export default Intro;
