const de = require('../shared-resources/translations/de')

const defaultState = {
  translation: de.translation,
  currentLang: de.langKey,
  theme: null,
  account: null,
  token: null,
  cart: [],
}

export default defaultState
