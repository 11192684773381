import Block from "../../UI/Block/Block";
import {
  ImageWrapper,
  Image,
  Headline,
  StyledSubline,
  FixLink,
  StyledExchange,
} from "./Exchange.Styled";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import caseOpen from "../../../assets/image/product/case-open.png";
import caseClosed from "../../../assets/image/product/case-closed.png";
import useIsVisible from "../../helper/IsVisible/useIsVisible";

const Subline = ({ children }) => {
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);

  return (
    <div ref={ref}>
      {isVisible && <StyledSubline>{children}</StyledSubline>}
    </div>
  );
};

const Exchange = () => {
  const translation = useSelector((s) => s.translation);
  const blockRef = useRef(null);
  const [opacity, setOpacity] = useState(1);
  const [initialOffsetTop, setInitialOffsetTop] = useState(false);

  useEffect(() => {
    const calcOpacity = () => {
      const scrollOffset = window.scrollY;
      if (blockRef.current) {
        if (!initialOffsetTop) {
          setInitialOffsetTop(blockRef.current.offsetTop - 0);
        } else if (scrollOffset > initialOffsetTop) {
          const end = initialOffsetTop;
          const delta = scrollOffset - end;
          setOpacity(1 - delta / 600);
          return;
        }
      }
      setOpacity(0);
    };

    window.addEventListener("scroll", calcOpacity);

    return () => {
      window.removeEventListener("scroll", calcOpacity);
    };
  }, [initialOffsetTop]);

  return (
    <Block sticky height="250vh" ref={blockRef} overflow="hidden" minified>
      <Block height="fit-content">
        <StyledExchange>
          <FixLink>{translation.product.exchange.fixlinkTitle}</FixLink>
          <div>
            {translation.product.exchange.headline.map((h) => (
              <Headline key={h}>{h}</Headline>
            ))}
          </div>
          <Subline>{translation.product.exchange.subline}</Subline>
          <ImageWrapper animation={"fadeInUp"}>
            <Image src={caseOpen} opacity={opacity === 0 ? 0 : 1 - opacity} />
            <Image src={caseClosed} opacity={opacity === 0 ? 1 : opacity} />
          </ImageWrapper>
        </StyledExchange>
      </Block>
    </Block>
  );
};

export default Exchange;
