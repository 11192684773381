import styled, { keyframes } from "styled-components";
import { Animated, float } from "../../helper/Animated/Animated.Styled";
import Flex from "../../UI/Flex/Flex";

export const SmallTitle = styled.div`
  color: ${({ theme }) => theme.color.font.danger};
  font-weight: bold;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 70px;
  font-family: "bold";
  line-height: 70px;
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  font-weight: bold;
  text-align: center;
`;

export const CTA = styled.div`
  padding: 5px 30px;
  display: block;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.color.backgroundRed};
  color: ${({ theme }) => theme.color.font.secondary};
  -webkit-box-shadow: 0px 0px 10px -5px #000000;
  box-shadow: 0px 0px 10px -5px #000000;
`;

export const SlideInContent = styled(Animated)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
`;

export const ImageWrapper = styled(Animated)`
  text-align: center;
  position: relative;
`;

export const Image = styled.img`
  height: 50vh;
  /* animation: ${float} 3s ease-in-out infinite; */
`;

export const Layer = styled.div`
  position: absolute;
  min-height: 100vh;
  min-width: 100%;
  z-index: ${({ zIndex }) => zIndex || 1};
  background-image: ${({ img }) => `url(${img})`};
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center 20vh;
  ${({ centerContent }) =>
    centerContent &&
    `
    display: flex;
  align-items: center;
  justify-content: center;
  `}
`;

export const BackgroundImage = styled(Animated)`
  background-image: ${({ img }) => `url(${img})`};
  height: 100vh;
  width: 101%;
  background-size: cover;
  background-position: center center;
`;

export const StyledEasyLan = styled.div``;

export const resize = keyframes`
    0% {
    width: 325px;
	}
	50% {
    width: 300px;
	}
	100% {
    width: 325px;
	}
`;

export const Shadow = styled.div`
  height: 35px;
  background: rgb(0, 120, 240);
  background: linear-gradient(
    180deg,
    rgba(0, 120, 240, 0.7) 0%,
    rgba(49, 93, 170, 0.7) 100%
  );
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  animation: ${resize} 3s ease-in-out infinite;
`;

export const SubMenuHintText = styled.span``;
export const SubMenuHintLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const IconLink = styled.a``;

export const StyledIcon = styled.img`
  height: ${({ big }) => (big ? "100px" : "60px")};
`;

export const SubMenuFlex = styled(Flex)`
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

export const IconWrapper = styled.div`
  text-align: right;
`;

export const Icon = styled.img`
  height: 100px;
  margin-top: 100px;
`;
