import { useSelector } from "react-redux";
import { Separator, StyledFooter, Text } from "./Footer.Styled";

const Footer = ({ separatorColor }) => {
  const translation = useSelector((s) => s.translation);

  return (
    <StyledFooter>
      <Text>{translation.footer.dataProtection}</Text>
      <Separator color={separatorColor}>|</Separator>
      <Text>{translation.footer.imprint}</Text>
      <Separator color={separatorColor}>|</Separator>
      <Text>{translation.footer.contact}</Text>
    </StyledFooter>
  );
};

export default Footer;
