import bg from '../../assets/image/product/saas/bg.jpg'
import Flex from '../UI/Flex/Flex'
import Block from '../UI/Block/Block'
import saas from '../../assets/image/product/saas/saas.png'
import button from '../../assets/image/product/saas/button.png'
import {
  Title,
  ImageWrapper,
  Image,
  Layer,
  BackgroundImage,
  StyledSaas,
  SmallTitle,
  SlideInContent,
  SubTitle,
  CTA,
} from './Saas.Styled'
import { useSelector } from 'react-redux'
import Layout from '../UI/Layout/Layout'

const Intro = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <Layout glassHeader addHeaderPadding={false}>
      <StyledSaas>
        <Block height="100vh">
          <Layer zIndex={1}>
            <BackgroundImage img={bg} animation={'fadeIn'} />
          </Layer>
          <Layer zIndex={2} centerContent>
            <Flex wrap="no-wrap" align="center" gap="10vw">
              <SlideInContent animation={'fadeInLeft'}>
                <SmallTitle>{translation.saas.smallTitle}</SmallTitle>
                <Title>
                  {translation.saas.title.text1}
                  <br />
                  {translation.saas.title.text2}
                </Title>
                <SubTitle>{translation.saas.subtitle}</SubTitle>
                <CTA src={button} />
              </SlideInContent>
              <ImageWrapper animation={'fadeInRight'}>
                <Image src={saas} />
              </ImageWrapper>
            </Flex>
          </Layer>
        </Block>
      </StyledSaas>
    </Layout>
  )
}

export default Intro
