import Flex from "../UI/Flex/Flex";
import Block from "../UI/Block/Block";
import {
  Title,
  ImageWrapper,
  Image,
  Layer,
  BackgroundImage,
  StyledEasyLan,
  SmallTitle,
  SlideInContent,
  SubTitle,
  CTA,
  IconLink,
  SubMenuFlex,
  StyledIcon,
} from "./EasyLan.Styled";
import { useSelector } from "react-redux";
import Layout from "../UI/Layout/Layout";
import SubMenuHint from "../SubMenuHint/SubMenuHint";
import bg from "../../assets/image/easyLan/bg.svg";
import textBG from "../../assets/image/easyLan/text-bg.svg";
import easylan from "../../assets/image/easyLan/easylan.svg";
import dualboot from "../../assets/image/easyLan/dualboot.svg";
import e2000 from "../../assets/image/easyLan/e2000.svg";
import fixlink from "../../assets/image/easyLan/fixlink.svg";
import hds from "../../assets/image/easyLan/hds.svg";
import lsa from "../../assets/image/easyLan/lsa.svg";
import prelink from "../../assets/image/easyLan/prelink.svg";
import wire from "../../assets/image/easyLan/wire.svg";

const Icon = ({ src, href, big }) => {
  return (
    <IconLink href={href}>
      <StyledIcon src={src} big={big} />
    </IconLink>
  );
};

const EasyLan = () => {
  const translation = useSelector((s) => s.translation);

  return (
    <Layout logo={easylan} headerLineColor={"red"}>
      <SubMenuHint>
        <SubMenuFlex>
          <Icon src={fixlink} href="/fixlink" />
          <Icon src={prelink} />
          <Icon src={lsa} />
          <Icon src={dualboot} />
          <Icon src={e2000} />
          <Icon src={hds} />
        </SubMenuFlex>
      </SubMenuHint>
      <StyledEasyLan>
        <Block>
          <Layer zIndex={1}>
            <BackgroundImage img={bg} animation={"fadeIn"} />
          </Layer>
          <Layer img={textBG} zIndex={2} centerContent>
            <Flex wrap="no-wrap" align="center" justify={"space-between"}>
              <SlideInContent animation={"fadeInLeft"}>
                <SmallTitle>{translation.easylan.smallTitle}</SmallTitle>
                <Title>
                  {translation.easylan.title.text1}
                  <br />
                  {translation.easylan.title.text2}
                </Title>
                <SubTitle>{translation.easylan.subtitle}</SubTitle>
                <CTA>{translation.easylan.cta}</CTA>
              </SlideInContent>
              <ImageWrapper animation={"fadeInRight"}>
                <Image src={wire} />
              </ImageWrapper>
            </Flex>
          </Layer>
        </Block>
      </StyledEasyLan>
    </Layout>
  );
};

export default EasyLan;
