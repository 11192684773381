import styled, { keyframes } from "styled-components";
import Container from "../../UI/Container/Container";

export const StyledSelector = styled.div``;

const slideInUpAnimation = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
  `;

export const Content = styled(Container)`
  animation: 2s ${slideInUpAnimation};
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  align-items: center;
  height: 60vh;
  gap: 5vw;
`;
export const CustomFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.div`
  width: 50%;
  height: 100%;
  background-image: ${({ src }) => `url(${src})`};
  background-position: center center;
  background-size: 70%;
  background-repeat: no-repeat;
`;
export const TextWrapper = styled.div`
  width: 50%;
`;

export const FixLink = styled.div`
  color: ${({ theme }) => theme.color.font.danger};
`;
export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: "bold";
  font-size: ${({ theme }) => theme.size.lg};
`;
export const Text = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
`;
