import styled from "styled-components";

export const HighlightTextWrapper = styled.div`
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`;
export const HighlightText = styled.div`
  margin-top: 25px;
  font-size: ${({ theme }) => theme.size.lg};
  font-weight: ${({ active }) => (active ? "bolder" : "regular")};
  color: ${({ active, theme }) =>
    active ? theme.color.font.primary : theme.color.font.thirdary};
  &:first-child {
    margin-top: 0;
  }
`;
export const HighlightTextCenter = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  height: 100%;
`;

export const FixLink = styled.span`
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.danger};
  padding-right: 10px;
`;
