import styled, { keyframes } from "styled-components";
import { float, Animated } from "../../helper/Animated/Animated.Styled";

export const resize = keyframes`
    0% {
    width: 275px;
	}
	50% {
    width: 250px;
	}
	100% {
    width: 275px;
	}
`;

export const StyledDetail = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 50px 0;
`;

export const Icon = styled.img`
  display: block;
  height: 50px;
  margin: 0px auto;
`;

export const HeadlineHint = styled.div`
  margin-bottom: 25px;
  color: ${({ theme }) => theme.color.font.petrol};
  text-align: center;
`;

export const Headline = styled.div`
  color: white;
  text-align: center;
  font-size: 40px;
  font-family: "bold";
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
`;

export const Text = styled.div`
  color: white;
  text-align: center;
  font-size: ${({ theme }) => theme.size.sm};
  margin-top: 25px;
`;

export const DetailSection = styled.div`
  display: flex;
  margin: 50px 0 50px 0;
  gap: 100px;
  height: 500px;
`;

export const DetailWrapper = styled.div`
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || "left"};
  flex-wrap: wrap;
`;

export const ImageWrapper = styled(Animated)`
  width: 30%;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  animation: ${float} 3s ease-in-out infinite;
`;

export const Shadow = styled.div`
  height: 30px;
  bottom: -40px;
  background: rgb(0, 120, 240);
  background: linear-gradient(
    180deg,
    rgba(0, 120, 240, 0.7) 0%,
    rgba(49, 93, 170, 0.7) 100%
  );
  position: absolute;
  left: 50%;
  transform: translateX(-32.5%);
  border-radius: 50%;
  animation: ${resize} 3s ease-in-out infinite;
`;

export const StyledSingleDetail = styled(Animated)`
  ${({ right }) => right && `text-align: right;`}
`;

export const SingleDetailValue = styled.div`
  font-size: 40px;
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.petrol};
`;

export const SingleDetailText = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
`;
