import * as types from './type/type'
import * as translations from './translations'
import * as responsiveState from './responsiveState'
import * as account from './account'
import * as theme from './theme'
import * as token from './token'
import * as cart from './cart'
import defaultState from './defaultState'

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_TRANSLATION:
      return translations.set(state, action)
    case types.SET_RESPONSIVE_STATE:
      return responsiveState.set(state, action)
    case types.SET_ACCOUNT:
      return account.set(state, action)
    case types.SET_THEME:
      return theme.set(state, action)
    case types.SET_TOKEN:
      return token.set(state, action)
    case types.SET_CART:
      return cart.set(state, action)
    case types.ADD_CART_ITEM:
      return cart.add(state, action)
    case types.REMOVE_CART_ITEM:
      return cart.remove(state, action)
    case types.REMOVE_CART_POSITION:
      return cart.removePosition(state, action)
    default:
      return state
  }
}

export default reducer
