import Block from "../../UI/Block/Block";
import { StyledSplit, Image, CustomFlex } from "./Bubbles.Styled";
import { useRef } from "react";
import useIsVisible from "../../helper/IsVisible/useIsVisible";
import Flex from "../../UI/Flex/Flex";
import left from "../../../assets/image/product/bubbles/left.png";
import midTop from "../../../assets/image/product/bubbles/midTop.png";
import midBottom from "../../../assets/image/product/bubbles/midBottom.png";
import right from "../../../assets/image/product/bubbles/right.png";
import Selector from "../Selector/Selector";

const Split = ({ children, ...props }) => {
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);

  return (
    <div ref={ref} style={{ width: props.width }}>
      <StyledSplit isVisible={isVisible}>{children}</StyledSplit>
    </div>
  );
};

const Exchange = () => {
  const blockRef = useRef(null);

  return (
    <Block ref={blockRef} height="auto">
      <CustomFlex
        gap="50px"
        margin="100px 0"
        wrap="no-wrap"
        jusify="space-between"
        align={"center"}
      >
        <Split width="35%">
          <Image src={left} />
        </Split>
        <Split width="19.8%">
          <Flex gap="50px" align="center" justify={"center"}>
            <Image src={midTop} />
            <Image src={midBottom} />
          </Flex>
        </Split>
        <Split width="35%">
          <Image src={right} />
        </Split>
      </CustomFlex>
      <Selector />
    </Block>
  );
};

export default Exchange;
