import { useSelector } from "react-redux";
import Block from "../../UI/Block/Block";
import {
  Image,
  Title,
  Split,
  CustomFlex,
  FixLink,
  Text,
} from "./SideSlide.Styled";
import Bubbles from "../Bubbles/Bubbles";
import leftImage from "../../../assets/image/product/sideSlide/left.svg";
import rightImage from "../../../assets/image/product/sideSlide/right.svg";
import { useRef } from "react";
import useIsVisible from "../../helper/IsVisible/useIsVisible";

const AnimateIfVisible = ({ children, ...props }) => {
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);

  return (
    <Split ref={ref} style={{ width: props.width }}>
      {isVisible && children}
    </Split>
  );
};

const SideSlide = () => {
  const translation = useSelector((s) => s.translation);
  return (
    <Block height="auto" margin="0">
      <Block>
        <CustomFlex>
          <AnimateIfVisible>
            <Title duration={"1s"} animation={"fadeInLeft"}>
              <FixLink align={"right"}>fixLink®</FixLink>
              {translation.product.sideSlide.left.map((t) => (
                <Text align={"right"} key={t}>
                  {t}
                </Text>
              ))}
            </Title>
          </AnimateIfVisible>
          <AnimateIfVisible>
            <Image src={leftImage} />
          </AnimateIfVisible>
          <AnimateIfVisible>
            <Image src={rightImage} />
          </AnimateIfVisible>
          <AnimateIfVisible>
            <Title duration={"1s"} animation={"fadeInRight"}>
              <FixLink>fixLink®</FixLink>
              {translation.product.sideSlide.right.map((t) => (
                <Text key={t}>{t}</Text>
              ))}
            </Title>
          </AnimateIfVisible>
        </CustomFlex>
      </Block>
      <Bubbles />
    </Block>
  );
};

export default SideSlide;
