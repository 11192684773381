import styled from 'styled-components'

export const StyledContainer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 750px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 970px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 1300px;
  }
`
