import styled, { keyframes } from 'styled-components'
import Flex from '../../UI/Flex/Flex'

const slideInUpAnimation = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
  `
export const StyledSplit = styled.div`
  /* flex: 1 1 0px; */
  width: ${({ width }) => width};
  animation: 2s ${slideInUpAnimation};
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`

export const Image = styled.img`
  width: 100%;
  max-height: 100%;
`

export const Icon = styled.img`
  height: 70px;
`

export const CustomFlex = styled(Flex)`
  height: 100vh;
`
