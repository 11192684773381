import styled from "styled-components";

export const StyledLayout = styled.div``;

export const CoreContent = styled.div`
  min-height: ${({ addHeaderPadding, theme, isProduct }) =>
    addHeaderPadding ? `calc(100vh - ${theme.headerHeight})` : "100vh"};
  padding-top: ${({ addHeaderPadding, theme, isProduct }) =>
    addHeaderPadding ? `${theme.headerHeight}` : "0px"};
`;
