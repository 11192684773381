import styled, { keyframes } from "styled-components";
import { Animated } from "../../helper/Animated/Animated.Styled";

export const HighlightTextWrapper = styled.div`
  text-align: center;
`;
export const HighlightText = styled.span`
  font-size: 3rem;
  color: ${({ active, theme }) =>
    active ? theme.color.font.primary : theme.color.font.thirdary};
  ${({ active }) => active && `font-weight: bold;`}
`;
export const HighlightTextCenter = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  height: 100%;
`;

export const Layer = styled.div`
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  z-index: ${({ zIndex }) => zIndex || 1};
  ${({ centerContent }) =>
    centerContent &&
    `
    display: flex;
  align-items: center;
  justify-content: center;
  `}
`;

export const ImageWrapper = styled(Animated)`
  text-align: center;
  height: 600px;
  margin-top: 50px;
`;

export const Image = styled.img`
  height: 500px;
  opacity: ${({ opacity }) => opacity};
  z-index: ${({ zIndex }) => zIndex || 1};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Headline = styled(Animated)`
  font-family: "bold";
  text-align: center;
  font-size: ${({ theme }) => theme.size.lg};
  text-shadow: rgb(152, 152, 152) 0.5px 0 5px;
`;

const slideInUpAnimation = keyframes`
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0%);
  }
`;

export const StyledSubline = styled.div`
  animation: 0.75s ${slideInUpAnimation};
  text-align: center;
  width: 100%;
  font-size: ${({ theme }) => theme.size.sm};
`;

export const Icon = styled.img`
  height: 70px;
`;

export const FixLink = styled.div`
  color: ${({ theme }) => theme.color.font.danger};
  font-size: ${({ theme }) => theme.size.sm};
  text-align: center;
  margin-top: 25px;
`;

export const StyledExchange = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
