//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  productCategory: {
    hint: "House of Excellence",
    headline: ["MEHR INDIVIDUALITÄT", "GEHT NICHT"],
    hint2: "Konfiguration für ihre Netze",
    subMenuHint: {
      text: "Fragen zu unseren Produkten? ",
      link: "Jetzt kontaktieren",
    },
  },

  product: {
    subMenuHint: {
      initials: "SiCab",
      why: "Warum SiCab",
      technicalData: "Technische Daten",
    },
    block1: {
      cabinet: "Sichert Cabinet",
      original: "Das Original",
    },
    block2: {
      prefix: "fixLink®",
      sections: [
        {
          prefix: true,
          text: "ist Vorreiter in der Vernetzungstechnologie, gestaltet für unsere Kunden, die Wert auf eine Synthese aus Präzision und klarem, funktionalem Design legen.",
        },
        {
          text: "Für Experten, die Effizienz schätzen, bieten wir eine 360°-Lösung, die Komplexität minimiert und gleichzeitig die Leistungsfähigkeit eines durchdachten Designs maximiert.",
        },
        {
          prefix: true,
          text: "ist ein Pionier in der Umgestaltung von Arbeitsbereichen, der Fachwissen mit intuitiver Handhabung verbindet.",
        },
      ],
    },
    exchange: {
      fixlinkTitle: "fixLink®",
      headline: [
        "INTUITIVE HANDHABUNG TRIFFT AUF",
        "FORTSCHRITTLICHE TECHNOLOGIE",
      ],
      subline: "Entdecken Sie die nahtlose Verbindung der Zukunft.",
    },
    selector: {
      fixlink: "fixLink®",
      headline: "VERBINDUNGEN WAREN NOCH NIE SO EINFACH.",
      text: "Intuitive Handhabung trifft auf fortschrittliche Technologie; entdecken Sie die nahtlose Verbindung der Zukunft. fixLink.",
    },
    end: {
      headline: "Smart war noch nie so einfach.",
      text: "Sensing as a Service ist für alle SiCab-Gehäuse verfügbar. Mit einer einfachen Erweiterung werden unsere Gehäuse smart und liefern Umgebungsdaten, die für deine Infrastruktur relevant sind. Mit dem Zugriff auf unsere Serverlogik und Anwendungsoberflächen liefert SAS ein All-in-One-Paket.",
    },
    sideSlide: {
      left: ["Pro+ Keystone", "Kat.8.1"],
      right: ["Pro Keystone", "Kat.6"],
    },
  },

  easylan: {
    smallTitle: "fixLink®",
    title: {
      text1: "Einfachheit",
      text2: "in Verbindung",
    },
    subtitle: "Dein Link für die Zukunft",
    cta: "mehr",
  },
  saas: {
    smallTitle: "Sensing As a Service",
    title: {
      text1: "Dein Netz",
      text2: "Unser Upgrade",
    },
    subtitle: "Smart Regions einfach gemacht",
  },
  dcenter: {
    smallTitle: "Glasfaser",
    title: {
      text1: "Mehr „Plug & Play“",
      text2: "geht im FTTH nicht",
    },
    subtitle: "Konfektionierte Glasfasernetzverteiler",
    cta: "MEHR",
    detail: {
      headlineHint: "d.center",
      headline: ["Glasfasernetzverteiler", "für passive optische Netze"],
      sices: {
        value: "6",
        text: "Gehäusegrößen",
      },
      components: {
        value: "Integrierte",
        text: "Spleiß-Komponenten",
      },
      scenario: {
        value: "FTTH",
        text: "für alle Szenarien",
      },
      tubes: {
        value: "24 bis 144",
        text: "Röhrchen",
      },
      text: "Die flächendeckende Anbindung von Haushalten ans Gigabit-Zeitalter ist ohne Glasfaser nicht zu gestalten. Dies gilt sowohl für den ländlichen Raum als auch Ballungsgebiete und die Innenstädte. Fiber to the Home (FTTH) ist daher das Mittel der Wahl, um langfristig schnelle Datenraten für Verbraucher und Unternehmen zu gewährleisten.",
    },
  },

  imprint: {
    headline: "Impressum",
  },

  dataProtection: {
    headline: "Datenschutz",
  },

  header: {
    brands: "Unsere Marken",
    products: "Unsere Produkte",
    sustainability: "Nachhaltigkeit",
    location: "Standorte",
    career: "Karriere",
    downloads: "Downloads",
    technicalData: "Technische Daten",
    buyCTA: "kaufen",
    whyPrefix: "Warum ",
  },

  footer: {
    dataProtection: "Datenschutz",
    imprint: "Impressum",
    contact: "Kontakt",
  },

  generic: {
    street: "Markt 36",
    city: "41836 Hückelhoven",
    phone: "+49 30 74707-0",
    fax: "+49 30 74707-20",
    mail: "info@sichert.com",
  },

  UI: {
    autocomplete: {
      searchError: "Suche konnte nicht ausgeführt werden.",
      searchEmpty: "Keine Ergebnisse",
    },
    dropdown: {
      emptySelection: "--- bitte wählen ---",
    },
  },
};

module.exports = {
  translation,
  langKey: "DE",
};
