export const SET_RESPONSIVE_STATE = 'set_responsive_state'

export const SET_TRANSLATION = 'set_translation'

export const SET_ACCOUNT = 'set_account'

export const SET_THEME = 'set_theme'

export const SET_TOKEN = 'set_token'

export const SET_CART = 'set_cart'

export const ADD_CART_ITEM = 'add_cart_item'

export const REMOVE_CART_POSITION = 'remove_cart_position'

export const REMOVE_CART_ITEM = 'remove_cart_item'
