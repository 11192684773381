import Container from '../UI/Container/Container'
import { StyledSubMenuHint } from './SubMenuHint.Styled'

const SubMenuHint = ({ sticky, respectSubMenuHeight, noBackgroundColor, children, inverted }) => {
  return (
    <StyledSubMenuHint
      respectSubMenuHeight={respectSubMenuHeight}
      noBackgroundColor={noBackgroundColor}
      animation="slideInDown"
      sticky={sticky}
      inverted={inverted}
    >
      <Container>{children}</Container>
    </StyledSubMenuHint>
  )
}

export default SubMenuHint
