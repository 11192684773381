import styled, { keyframes } from 'styled-components'
import { Animated, float } from '../helper/Animated/Animated.Styled'

export const SmallTitle = styled.div`
  color: white;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`

export const Title = styled.div`
  color: white;
  font-size: 70px;
  font-family: 'bold';
  text-align: center;
`

export const SubTitle = styled.div`
  color: white;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`

export const CTA = styled.img`
  height: 40px;
  margin: 20px auto 0px auto;
  display: block;
`

export const SlideInContent = styled(Animated)``

export const ImageWrapper = styled(Animated)`
  text-align: center;
  position: relative;
`

export const Image = styled.img`
  height: 650px;
  animation: ${float} 3s ease-in-out infinite;
`

export const Layer = styled.div`
  position: absolute;
  min-height: 100vh;
  min-width: 100%;
  z-index: ${({ zIndex }) => zIndex || 1};
  ${({ centerContent }) =>
    centerContent &&
    `
    display: flex;
  align-items: center;
  justify-content: center;
  `}
`

export const BackgroundImage = styled(Animated)`
  background-image: ${({ img }) => `url(${img})`};
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center center;
`

export const StyledSaas = styled.div``

export const resize = keyframes`
    0% {
    width: 325px;
	}
	50% {
    width: 300px;
	}
	100% {
    width: 325px;
	}
`
