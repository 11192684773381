import Block from "../../UI/Block/Block";
import { getUUID } from "../../../utility";
import {
  HighlightText,
  HighlightTextWrapper,
  HighlightTextCenter,
  FixLink,
} from "./TextHighlight.Styled";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const TextHighlight = () => {
  const translation = useSelector((s) => s.translation);
  const [initialOffsetTop, setInitialOffsetTop] = useState(false);
  const blockRef = useRef(null);
  const [scrollOffset, setScrollOffset] = useState(0);

  const getHighlightTextIndex = (itemIndex) => {
    if (blockRef.current) {
      if (!initialOffsetTop) {
        setInitialOffsetTop(blockRef.current.offsetTop);
      } else if (scrollOffset > initialOffsetTop) {
        const sectionLength =
          blockRef.current.clientHeight /
          translation.product.block2.sections.length;
        const activeSectionIndexCalc = Math.round(
          (scrollOffset - initialOffsetTop) /
            (sectionLength - (sectionLength / 100) * 20)
        );
        const activeSectionIndex =
          activeSectionIndexCalc >
          translation.product.block2.sections.length - 1
            ? translation.product.block2.sections.length - 1
            : activeSectionIndexCalc;
        return activeSectionIndex === itemIndex;
      }
    }
    return itemIndex === 0;
  };

  useEffect(() => {
    const handleScroll = () => setScrollOffset(window.scrollY);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Block sticky height="auto" ref={blockRef} overflow="hidden">
      <Block container margin="0 0 150vh 0">
        <HighlightTextCenter>
          <HighlightTextWrapper>
            {translation.product.block2.sections.map((section, i) => (
              <HighlightText key={getUUID()} active={getHighlightTextIndex(i)}>
                {section.prefix && (
                  <FixLink>{translation.product.block2.prefix}</FixLink>
                )}
                {section.text}
              </HighlightText>
            ))}
          </HighlightTextWrapper>
        </HighlightTextCenter>
      </Block>
    </Block>
  );
};

export default TextHighlight;
