import { forwardRef } from "react";
import { StyledBlock, CustomContainer } from "./Block.Styled";

const Block = (
  {
    height,
    margin,
    container,
    children,
    sticky,
    overflow,
    backgroundImage,
    backgroundColor,
    minified,
    ...props
  },
  ref
) => {
  return (
    <StyledBlock
      sticky={sticky}
      height={height}
      margin={margin}
      overflow={overflow}
      ref={ref}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      minified={minified}
      {...props}
    >
      {container ? <CustomContainer>{children}</CustomContainer> : children}
    </StyledBlock>
  );
};

export default forwardRef(Block);
