import styled from 'styled-components'
import { Animated, float } from '../helper/Animated/Animated.Styled'

export const StyledProduct = styled.div`
  min-height: 100vh;
`

export const Title = styled(Animated)`
  color: white;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
`

export const Split = styled.div`
  flex: 1 1 0px;
  text-align: ${({ align }) => align || 'left'};
`

export const ImageWrapper = styled(Animated)`
  text-align: center;
`

export const Image = styled.img`
  height: 500px;
  animation: ${float} 3s ease-in-out infinite;
`

export const SubMenuHintItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ highlight, theme }) => (highlight ? theme.size.md : theme.size.sm)};
`

export const BuyButton = styled.img`
  height: 30px;
`

export const Layer = styled.div`
  position: absolute;
  min-height: 100vh;
  min-width: 100%;
  z-index: ${({ zIndex }) => zIndex || 1};
  ${({ centerContent }) =>
    centerContent &&
    `
    display: flex;
  align-items: center;
  justify-content: center;
  `}
`

export const BackgroundImage = styled(Animated)`
  background-image: ${({ img }) => `url(${img})`};
  height: ${({ theme, height }) => height || `calc(100vh - ${theme.headerHeight})`};
  width: 100%;
  background-size: cover;
  background-position: center center;
`

export const SicabIcon = styled.img`
  height: 125px;
`
