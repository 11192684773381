import Layout from "../UI/Layout/Layout";
import {
  StyledProductCategory,
  Headline,
  Hint,
  CaseImage,
  Spacer,
  SubMenuHintText,
  SubMenuHintLink,
  StyledIcon,
  IconLink,
  CustomBlock,
  Center,
} from "./ProductCategory.Styled";
import caseImage from "../../assets/image/productCategory/ipad.png";
import { useSelector } from "react-redux";
import SubMenu from "../SubMenu/SubMenu";
import SubMenuHint from "../SubMenuHint/SubMenuHint";
import Flex from "../UI/Flex/Flex";
import easyLan from "../../assets/image/productCategory/easylan.svg";
import dCenter from "../../assets/image/productCategory/dcenter.svg";
import hauseOfExcellence from "../../assets/image/productCategory/hoe.svg";

const Icon = ({ src, href }) => {
  return (
    <IconLink href={href}>
      <StyledIcon src={src} />
    </IconLink>
  );
};

const ProductCategory = () => {
  const translation = useSelector((s) => s.translation);
  return (
    <Layout>
      <SubMenu>
        <Center>
          <SubMenuHintText>
            {translation.productCategory.subMenuHint.text}
          </SubMenuHintText>
          <SubMenuHintLink href="/">
            {translation.productCategory.subMenuHint.link}
          </SubMenuHintLink>
        </Center>
      </SubMenu>
      <SubMenuHint respectSubMenuHeight>
        <Flex justify={"space-between"} align={"center"}>
          {/* sicab */}
          <Icon src={easyLan} href="/easylan" />
          {/* fcc */}
          <Icon src={dCenter} href="/dcenter" />
          <Icon src={hauseOfExcellence} />
        </Flex>
      </SubMenuHint>
      <StyledProductCategory>
        <CustomBlock>
          <Spacer height="200px" />
          <Hint>{translation.productCategory.hint}</Hint>
          {translation.productCategory.headline.map((h) => (
            <Headline key={h}>{h}</Headline>
          ))}
          <Hint>{translation.productCategory.hint2}</Hint>
          <CaseImage src={caseImage} />
        </CustomBlock>
      </StyledProductCategory>
    </Layout>
  );
};

export default ProductCategory;
