import { StyledLayout, CoreContent } from "./Layout.Styled";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ResponsiveState from "./ResponsiveState/ResponsiveState";
import FlashIntroScreen from "./FlashIntroScreen/FlashIntroScreen";

const Layout = ({
  children,
  className,
  addHeaderPadding = true,
  hideHeader = false,
  flashIntroScreen = false,
  glassHeader = false,
  logo,
  headerLineColor,
  showFooter = false,
}) => {
  return (
    <StyledLayout className={className}>
      <FlashIntroScreen enable={flashIntroScreen}>
        <ResponsiveState />
        {!hideHeader && (
          <Header
            logo={logo}
            lineColor={headerLineColor}
            glassHeader={glassHeader}
          />
        )}
        <CoreContent addHeaderPadding={addHeaderPadding}>
          {children}
        </CoreContent>
        {showFooter && <Footer separatorColor={headerLineColor} />}
      </FlashIntroScreen>
    </StyledLayout>
  );
};

export default Layout;
